* {
  font-size: 14px;
  font-family: Roboto;
}

#main-content {
  max-width: 1440px;
  margin: 0 auto;
}

#react-admin-title {
  padding-left: 15px;
}

#react-admin-title span {
  display: none;
}

#reset-password {
  height: 100vh;
  background-size: cover;
  background-image: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)
}

#reset-password-paper {
  display: flex;
  margin-top: 6em;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.RaAppBar-menuButton {
  display: none !important;
}

.RaShow-main > div:first-of-type {
  background-color: unset !important;
  box-shadow: unset !important;
}
